import Link from 'next/link';
import Image from 'next/legacy/image';
import {
  Box,
  Heading,
  Text,
  Flex,
  Button,
  AspectRatio,
  Icon
} from '@chakra-ui/react';
import { GridPatternDark, LayoutContainer } from '@components/common';
import { URLS } from '@lib';
import { openPopupWidget } from 'react-calendly';
import { ArrowRightIcon } from '@heroicons/react/solid';
import { analytics } from 'pages';

export function Pricing(): JSX.Element {
  return (
    <LayoutContainer verticalPadding="sm">
      <Flex
        flexDir={{ base: 'column', lg: 'row' }}
        justifyContent="space-around"
        pos="relative"
        p={{ lg: 6 }}
      >
        <AspectRatio
          ratio={1400 / 1190}
          w="1196px"
          h="962px"
          pos="absolute"
          top="-46px"
          left="258px"
          pointerEvents="none"
          draggable={false}
          userSelect="none"
        >
          <GridPatternDark />
        </AspectRatio>

        {/* Start Now Section */}

        <Flex
          flexDir="column"
          alignItems={{ base: 'center', lg: 'flex-start' }}
          justifyContent={{ base: 'center', lg: 'space-evenly' }}
          mb={12}
          mr={{ lg: 12 }}
        >
          <Box
            boxSize={{
              base: 16,
              md: 20
            }}
            mb={10}
            pos="relative"
          >
            <Image
              src="/images/clerk-logomark-square.svg"
              alt="Clerk's logo"
              layout="fill"
            />
          </Box>

          <Heading mb={4} zIndex={2} textAlign={{ base: 'center', lg: 'left' }}>
            Start Now,
            <br />
            No Strings Attached
          </Heading>

          <Text color="black" paddingTop={2} mb={8}>
            Start completely free for up to 5,000 monthly active users and up to
            10 monthly active orgs. No credit card required.
          </Text>

          <Box
            display="flex"
            w={{ base: 'full', sm: 'auto' }}
            flexDir={{ base: 'column', sm: 'row' }}
            justifyContent={{ base: 'center', sm: 'left' }}
            alignContent={{ base: 'center', sm: 'left' }}
          >
            <Link
              legacyBehavior
              href={process.env.NEXT_PUBLIC_DASHBOARD_URL as string}
              passHref
            >
              <Button
                as="a"
                w={{ base: 'full', lg: 'auto' }}
                cursor="pointer"
                variant="base"
                onClick={() =>
                  analytics.track(
                    'Marketing_Home_Start Building Button Clicked',
                    { surface: 'Marketing', location: 'Home' }
                  )
                }
              >
                Start building
              </Button>
            </Link>

            <Button
              w={{ base: 'full', lg: 'auto' }}
              variant="white"
              onClick={() => {
                openPopupWidget({
                  url: URLS.calendlyUrl
                });
                analytics.track(
                  'Marketing_Home_Schedule a Demo Button Clicked',
                  { surface: 'Marketing', location: 'Home' }
                );
              }}
              mx={{ base: 0, sm: 4 }}
              my={{ base: 4, sm: 0 }}
            >
              Schedule a demo
            </Button>
          </Box>
        </Flex>

        <Flex
          flexDir="column"
          alignItems="flex-start"
          justifyContent={{ base: 'center', lg: 'space-evenly' }}
          ml={{ lg: '32px' }}
          minH="480px"
          maxW="620px"
        >
          <Box
            boxSize={{
              base: 16,
              md: 20
            }}
            pos="relative"
            mb={{ base: 12, lg: 0 }}
          >
            <Box
              h="36px"
              w="4px"
              bg="primary.500"
              pos="absolute"
              left="-30%"
              top="30%"
              display={{ base: 'none', lg: 'inline-flex' }}
            ></Box>
            <Image src="/images/tag-icon.svg" layout="fill" alt="" />
          </Box>

          <Box>
            <Flex flexDir={{ base: 'column', lg: 'row' }}>
              <Heading size={'h5'} whiteSpace="nowrap" mr={1}>
                Pricing built for
              </Heading>
              <Heading size={'h5'} color="primary.500" whiteSpace="nowrap">
                businesses of all sizes.
              </Heading>
            </Flex>

            <Text
              color="black"
              paddingTop={5}
              minW={{ lg: '400px' }}
              w={{ lg: '80%' }}
              mb={{ base: 8, lg: 0 }}
            >
              Learn more about our transparent per-user costs to estimate how
              much your company could save by implementing Clerk.
            </Text>
          </Box>

          <Box
            display="flex"
            flexDir={{ base: 'column', sm: 'row' }}
            justifyContent={{ base: 'center', sm: 'flex-end' }}
            alignContent={{ base: 'center', sm: 'center' }}
            pt={6}
          >
            <Link legacyBehavior href={URLS.pricing} passHref>
              <Button
                as="a"
                px={8}
                variant="white"
                w={{ base: 'full', lg: 'auto' }}
                onClick={() =>
                  analytics.track(
                    'Marketing_Home_View Pricing Button Clicked',
                    { surface: 'Marketing', location: 'Home' }
                  )
                }
              >
                View pricing
                <Icon
                  as={ArrowRightIcon}
                  ml={1}
                  pos="relative"
                  transition="margin-left .2s"
                />
              </Button>
            </Link>
          </Box>
        </Flex>

        {/* Pricing section */}
      </Flex>
    </LayoutContainer>
  );
}
