import React from 'react';
import { Box, Stack } from '@chakra-ui/react';
import { THEMES } from '@lib';
import { CustomizationPreviewMobile } from './customization-preview-mobile';
import { CustomizationPreviewDesktop } from './customization-preview-desktop';

export type ThemeProps = {
  name: string;
  value: string;
  background: string;
  theme: any;
  image: string;
  bgImageDesktop: string;
  bgImageMobile: string;
};

const themes = [
  {
    name: 'Light',
    value: 'light',
    background: '',
    theme: undefined,

    image: `/images/components/themes/light.svg`,
    bgImageDesktop: `/images/components/themes/desktop/browser-background.svg`,
    bgImageMobile: `/images/components/themes/mobile/mobile-browser-background.svg`
  },
  {
    name: 'Dark',
    value: 'dark',
    background: '#25282e',
    theme: THEMES.dark,

    image: `/images/components/themes/dark.svg`,
    bgImageDesktop: `/images/components/themes/desktop/dark-bg.svg`,
    bgImageMobile: `/images/components/themes/mobile/dark-bg.svg`
  },
  {
    name: 'Simple',
    value: 'simple',
    background: '#F7F9FC',
    theme: THEMES.simple,

    image: `/images/components/themes/simple.svg`,
    bgImageDesktop: `/images/components/themes/desktop/browser-background.svg`,
    bgImageMobile: `/images/components/themes/mobile/simple-bg.svg`
  },
  {
    name: 'Wave',
    value: 'wave',
    theme: THEMES.wave,
    background: 'url("/images/wave.svg")',

    image: `/images/components/themes/wave.svg`,
    bgImageDesktop: `/images/components/themes/desktop/browser-background.svg`,
    bgImageMobile: `/images/components/themes/mobile/wave-bg.svg`
  },
  {
    name: 'Abstract',
    value: 'abstract',
    background: 'url("/images/bg-abstract.jpg")',
    theme: THEMES.abstract,

    image: `/images/components/themes/abstract.svg`,
    bgImageDesktop: `/images/components/themes/desktop/abstract-bg.svg`,
    bgImageMobile: `/images/components/themes/mobile/abstract-bg.svg`
  },
  {
    name: 'Retro',
    value: 'retro',
    background: '#008080',
    theme: THEMES.retro,

    image: `/images/components/themes/retro.svg`,
    bgImageDesktop: `/images/components/themes/desktop/retro-bg.svg`,
    bgImageMobile: `/images/components/themes/mobile/retro-bg.svg`
  },
  {
    name: 'Neobrutalism',
    value: 'neobrutalism',
    background: '#ECEBF5',
    theme: THEMES.neobrutalism,

    image: `/images/components/themes/neobrutalism.svg`,
    bgImageDesktop: `/images/components/themes/desktop/neobrutalism-bg.svg`,
    bgImageMobile: `/images/components/themes/mobile/neobrutalism-bg.svg`
  },
  {
    name: 'Yolo',
    value: 'yolo',
    background: '#FFC62A',
    theme: THEMES.yolo,

    image: `/images/components/themes/yolo.svg`,
    bgImageDesktop: `/images/components/themes/desktop/yolo-bg.svg`,
    bgImageMobile: `/images/components/themes/mobile/yolo-bg.svg`
  }
];

export function Customization(): JSX.Element {
  const [currentTheme, setCurrentTheme] = React.useState<any>(themes[0]);

  const appearance = currentTheme?.theme;
  const appearanceCode = appearance
    ? JSON.stringify(appearance, null, ' ')
    : '// Default light theme from ClerkProvider';

  return (
    <Box>
      <Stack
        pos="relative"
        flexDir={{ base: 'column' }}
        justifyContent={'center'}
        alignItems="center"
        w="100%"
      >
        <Box display={{ base: 'none', xl: 'flex' }}>
          <CustomizationPreviewDesktop
            themes={themes}
            setCurrentTheme={setCurrentTheme}
            currentTheme={currentTheme}
            snippet={appearanceCode}
            header={'Component Customization'}
            subheader={
              'Helper components that help control the flow of your application across different states.'
            }
            imgDirection="right"
            icon={'/images/components/gear-icon.svg'}
          />
        </Box>
        <Box display={{ base: 'flex', xl: 'none' }}>
          <CustomizationPreviewMobile
            themes={themes}
            setCurrentTheme={setCurrentTheme}
            currentTheme={currentTheme}
            snippet={appearanceCode}
            header={'Component Customization'}
            subheader={
              'Helper components that help control the flow of your application across different states.'
            }
            icon={'/images/components/gear-icon.svg'}
          />
        </Box>
      </Stack>
    </Box>
  );
}
