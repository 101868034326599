import Link from 'next/link';
import classNames from 'classnames';
import { URLS } from 'lib/constants';
import {
  IdentificationIcon,
  LoginIcon,
  LinkIcon,
  GlobeAltIcon,
  GlobeIcon,
  ShieldCheckIcon,
  UserAddIcon,
  UserCircleIcon,
  KeyIcon,
  LockClosedIcon,
  ChatAltIcon,
  CubeIcon,
  ColorSwatchIcon,
  TemplateIcon,
  OfficeBuildingIcon
} from '@heroicons/react/solid';
import {
  ChevronIcon,
  ShieldPlusIcon,
  NextJSIcon,
  GatsbyIcon,
  ReactIcon,
  ExpoIcon,
  NodeIcon,
  RubyIcon,
  HasuraIcon,
  FirebaseIcon,
  SupabaseIcon,
  RedwoodIcon,
  FaunaIcon,
  RemixIcon,
  GrafbaseIcon
} from '../../common/icons';
import Hover from './hover';
import Click from './click';
import { analytics } from 'pages';
const {
  nextAuth,
  react,
  remix,
  reactNative,
  node,
  ruby,
  components,
  emailMagicLinks,
  socialSSO,
  passwords,
  sessionMgmt,
  multifactorAuth,
  hasuraIntegration,
  supabaseIntegration,
  firebaseIntegration,
  grafbaseIntegration,
  gatsby,
  securityPage,
  passcodes,
  orgManagement,
  web3Page,
  authAtTheEdge,
  fauna,
  redwood,
  customizeComponents,
  authUserComponents,
  b2bComponents
} = URLS;

export const clerkComponents = [
  {
    name: 'Auth & User Components',
    href: authUserComponents,
    icon: UserCircleIcon
  },
  {
    name: 'B2B SaaS Components',
    href: b2bComponents,
    icon: OfficeBuildingIcon
  },
  {
    name: 'Component Customization',
    href: customizeComponents,
    icon: ColorSwatchIcon
  },
  {
    name: 'See All Components',
    href: components,
    icon: TemplateIcon
  }
];

export const features = [
  {
    name: 'Passwords',
    href: passwords,
    icon: KeyIcon
  },
  {
    name: 'Email magic links',
    href: emailMagicLinks,
    icon: LinkIcon
  },
  {
    name: 'Social SSO',
    href: socialSSO,
    icon: GlobeAltIcon
  },
  { name: 'Email & SMS passcodes (OTP)', href: passcodes, icon: ChatAltIcon },
  {
    name: 'Web3 authentication',
    href: web3Page,
    icon: CubeIcon
  },
  {
    name: 'Multifactor authentication',
    href: multifactorAuth,
    icon: ShieldPlusIcon
  },
  {
    name: 'Authentication at the edge',
    href: authAtTheEdge,
    icon: GlobeIcon
  },
  {
    name: 'Session management',
    href: sessionMgmt,
    icon: ShieldCheckIcon
  },
  {
    name: 'Security',
    href: securityPage,
    icon: LockClosedIcon
  }
  // {
  //   name: 'Organization management',
  //   href: orgManagement,
  //   icon: SwitchHorizontalIcon
  // },
];

const frameworks = [
  {
    name: 'Next.js',
    href: nextAuth,
    icon: NextJSIcon
  },
  {
    name: 'Remix',
    href: remix,
    icon: RemixIcon
  },
  {
    name: 'RedwoodJS',
    href: redwood,
    icon: RedwoodIcon
  },
  {
    name: 'Gatsby',
    href: gatsby,
    icon: GatsbyIcon
  },
  {
    name: 'React',
    href: react,
    icon: ReactIcon
  },
  {
    name: 'React Native / Expo',
    href: reactNative,
    icon: ExpoIcon
  }
  // {
  //   name: 'Swift',
  //   href: '/authentication/swift',
  //   comingSoon: true,
  // },
  // {
  //   name: 'Kotlin',
  //   href: '/authentication/kotlin',
  //   comingSoon: true,
  // },
];

const integrations = [
  {
    name: 'Hasura',
    href: hasuraIntegration,
    icon: HasuraIcon
  },
  {
    name: 'Supabase',
    href: supabaseIntegration,
    icon: SupabaseIcon
  },
  {
    name: 'Fauna',
    href: fauna,
    icon: FaunaIcon
  },
  {
    name: 'Firebase',
    href: firebaseIntegration,
    icon: FirebaseIcon
  },
  {
    name: 'Grafbase',
    href: grafbaseIntegration,
    icon: GrafbaseIcon
  }
];

const traditional = [
  {
    name: 'Node / Express',
    href: node,
    icon: NodeIcon
  },
  {
    name: 'Ruby / Rails',
    href: ruby,
    icon: RubyIcon
  }
];

export function ListItem({ item }) {
  const Icon = item.icon;

  if (item.comingSoon) {
    return (
      <li className="flow-root">
        <div>
          <span className="flex px-3 py-2 -mx-3 text-sm font-medium text-gray-500">
            {item.name}
            <span className="ml-2 rounded-full bg-blue-50 px-2.5 py-0.5 text-xs font-medium text-clerk-blue tracking-wide">
              Coming soon
            </span>
          </span>
        </div>
      </li>
    );
  }

  return (
    <li
      className="flow-root"
      onClick={() =>
        analytics.track('Marketing_Header_Menu Item Clicked', {
          ClickedOn: item.name,
          Page: item.href
        })
      }
    >
      <Link legacyBehavior href={item.href}>
        <a className="flex px-3 py-2 pr-20 -mx-3 text-sm font-medium text-gray-900 transition duration-150 ease-in-out rounded hover:bg-gray-menu-item-hover">
          {Icon && <Icon className="w-5 h-5 mr-2 text-gray-400" />}
          {item.name}
        </a>
      </Link>
    </li>
  );
}

export default function Product({ mobile, textColor }) {
  return (
    <>
      {mobile ? (
        <Click
          Button={({ open }) => (
            <button
              type="button"
              className={classNames(
                open ? 'text-gray-900' : 'text-gray-500',
                'w-full group inline-flex justify-between items-center text-base font-medium hover:text-gray-900 focus:outline-none lg:hidden'
              )}
            >
              Product
              <ChevronIcon
                className={classNames(
                  open
                    ? 'fill-current transform rotate-0'
                    : 'fill-current transform -rotate-90',
                  'flex-shrink-0 w-6 h-6 ml-2 group-hover:fill-current'
                )}
              />
            </button>
          )}
          popup={
            <div className="w-full px-2 transform sm:px-0">
              <div className="py-4 ">
                <div className="grid grid-cols-1 gap-20 sm:grid-cols-2">
                  <div className="w-full">
                    <h3 className="text-xs font-medium tracking-wide text-gray-500 uppercase">
                      Features
                    </h3>
                    <ul className="mt-2">
                      {features.map(({ icon, name, href }) => (
                        <ListItem
                          key={`feature-${href}`}
                          item={{
                            icon,
                            name,
                            href
                          }}
                        />
                      ))}
                    </ul>
                    <h3 className="mt-4 text-xs font-medium tracking-wide text-gray-500 uppercase">
                      Components
                    </h3>
                    <ul className="mt-2">
                      {clerkComponents.map(item => (
                        <ListItem key={item.name} item={item} />
                      ))}
                    </ul>
                    <h3 className="mt-4 text-xs font-medium tracking-wide text-gray-500 uppercase">
                      Jamstack Framework Integrations
                    </h3>
                    <ul className="mt-2">
                      {frameworks.map(item => (
                        <ListItem key={item.name} item={item} />
                      ))}
                    </ul>
                    <h3 className="mt-4 text-xs font-medium tracking-wide text-gray-500 uppercase">
                      Jamstack Database Integrations
                    </h3>
                    <ul className="mt-2">
                      {integrations.map(item => (
                        <ListItem key={item.name} item={item} />
                      ))}
                    </ul>
                    <h3 className="mt-4 text-xs font-medium tracking-wide text-gray-500 uppercase">
                      Traditional Framework Integrations
                    </h3>
                    <ul className="mt-2">
                      {traditional.map(item => (
                        <ListItem key={item.name} item={item} />
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      ) : (
        <Hover
          Button={() => (
            <button
              type="button"
              className={classNames(
                textColor,

                'py-4 group items-center text-md font-medium focus:outline-none hidden lg:inline-flex'
              )}
            >
              Product
            </button>
          )}
          popup={
            <div className="absolute left-0 z-30 px-2 ml-10 transform -translate-x-1/4 w-max sm:px-0">
              <div className="p-8 px-8 py-6 pr-20 overflow-hidden bg-white shadow-lg rounded-2xl ring-1 ring-black ring-opacity-5">
                <p className="mb-5 text-lg font-semibold text-black">Product</p>

                <div className="relative grid grid-cols-1 gap-20 sm:grid-cols-2">
                  <div>
                    <h3 className="text-xs font-medium tracking-wide text-gray-500 uppercase">
                      Features
                    </h3>
                    <ul className="mt-2">
                      {features.map(({ icon, name, href }) => (
                        <ListItem
                          key={`feature-${href}`}
                          item={{
                            icon,
                            name,
                            href
                          }}
                        />
                      ))}
                    </ul>
                    <h3 className="mt-4 text-xs font-medium tracking-wide text-gray-500 uppercase">
                      Components
                    </h3>
                    <ul className="mt-2">
                      {clerkComponents.map(item => (
                        <ListItem key={item.name} item={item} />
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h3 className="text-xs font-medium tracking-wide text-gray-500 uppercase">
                      Jamstack Framework Integrations
                    </h3>
                    <ul className="mt-2">
                      {frameworks.map(item => (
                        <ListItem key={item.name} item={item} />
                      ))}
                    </ul>
                    <h3 className="mt-4 text-xs font-medium tracking-wide text-gray-500 uppercase">
                      Jamstack Database Integrations
                    </h3>
                    <ul className="mt-2">
                      {integrations.map(item => (
                        <ListItem key={item.name} item={item} />
                      ))}
                    </ul>
                    <h3 className="mt-4 text-xs font-medium tracking-wide text-gray-500 uppercase">
                      Traditional Framework Integrations
                    </h3>
                    <ul className="mt-2">
                      {traditional.map(item => (
                        <ListItem key={item.name} item={item} />
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      )}
    </>
  );
}
