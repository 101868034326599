import React from 'react';
import { useUser } from '@clerk/nextjs';
import { analytics } from 'pages';

export function useSegmentIdentify() {
  if (typeof window !== 'undefined') {
    const { user } = useUser();
    const isIdentified = window.localStorage.getItem('segment-identified');
    React.useEffect(() => {
      if (user && !isIdentified) {
        analytics.identify(user.id, {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.primaryEmailAddress?.emailAddress,
          createdAt: user.createdAt?.toISOString()
        });
        localStorage.setItem('segment-identified', 'true');
      }
    }, [user]);
  }
  return;
}
